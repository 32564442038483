import request from '@/utils/request';
import config from '@/api/config';
import util from '@/utils/util';

//获取阿里云相关参数
export function getExamTicketNum(data) {
  return request({
    url: config.orderReviewCountInTicketHttp,
    method: 'post',
    data: data
  });
}

//获取品牌信息
export function getBrandInfo(data) {
  return request({
    url: config.brandInfoHttp,
    method: 'post',
    data: data
  });
}
export function changeLocale(data) {
  return request({
    url: config.changeLocaleHttp,
    method: 'post',
    data: data
  });
}
export function loctionList(data) {
  return request({
    url: config.locationListHttp,
    method: 'post',
    data: data
  });
}
export function handleUploadFun(url, data, upload) {
  return request({
    url: url,
    method: 'post',
    data: data,
    upload: upload,
    requestType: 'file'
  });
}
export function getServiceTypeAuthFun(data) {
  return request({
    url: config.serviceTypeAuthHttp,
    method: 'post',
    data: data
  });
}
export function uploadCommentFile(file, ossClient) {
  return uploadCommentFileFun(file, ossClient);
}

//获取阿里云相关参数
export function uploadCommentFileFun(file, ossClient) {
  return new Promise(function (resolve, reject) {
    var fileNameArr = file.name.split('.');
    var fileName = util.getUUID(32, 16) + '.' + fileNameArr[fileNameArr.length - 1]; //生成十位随机数
    var headers = {
      // 指定该Object被下载时的网页缓存行为。
      "Cache-Control": "no-cache",
      // 指定该Object被下载时的名称。
      "Content-Disposition": "attachment; filename=" + encodeURIComponent(file.name),
      // 指定该Object被下载时的内容编码格式。
      "Content-Encoding": "utf-8",
      // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
      "x-oss-forbid-overwrite": "true"
    };
    ossClient.multipartUpload(config.commentOssPath + '/' + fileName, file, {
      headers,
      parallel: 4,
      // 设置分片大小。默认值为10MB，最小值为100 KB。
      partSize: 10 * 1024 * 1024,
      progress: (p, cpt, res) => {
        // 获取上传进度。
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      console.log(err);
    });
  });
}

// 查询品牌
export function whoCanHandle(data) {
  return request({
    url: config.creatorOnAmdinHttp,
    method: 'post',
    data: data
  });
}

// 查询品牌
export function changeCompany(data) {
  return request({
    url: config.changeCompanyHttp,
    method: 'post',
    data: data,
    isLoading: true
  });
}

// 查询品牌
export function getBrandListFromCompany(data) {
  return request({
    url: config.brandListFromCompanyhttp,
    method: 'post',
    data: data
  });
}

// 查询品牌
export function getUserInfoList(data) {
  return request({
    url: config.userInfoHttp,
    method: 'post',
    data: data,
    noError: true
  });
}

// 查询品牌
export function getLogList(data) {
  return request({
    url: config.logListHttp,
    method: 'post',
    data: data
  });
}
export function getUserCompanyList(data) {
  return request({
    url: config.userCompanyListHttp,
    method: 'post',
    data: data
  });
}

//获取模块
export function getModuleList(data) {
  return request({
    url: config.moduleListHttp,
    method: 'post',
    data: data
  });
}

// 查询品牌
export function getBrandList(data) {
  return request({
    url: config.brandListForCreateTicketByHandHttp,
    method: 'post',
    data: data
  });
}

// 查询品牌
export function getBrandForCompanyList(data) {
  return request({
    url: config.userCompanyBrandListInHomeHttp,
    method: 'post',
    data: data
  });
}

// 查询运营端所有品牌
export function getAdminBrandList(data) {
  return request({
    url: config.riskBrandListHttp,
    method: 'post',
    data: data
  });
}

// 查询运营端所有品牌
export function getTagList(data) {
  return request({
    url: config.tagListHttp,
    method: 'post',
    data: data
  });
}

// 查询运营端所有品牌
export function addTag(data) {
  return request({
    url: config.addTagListInHomeHttp,
    method: 'post',
    data: data
  });
}

// 查询菜单列表
export function getToken(riskTicketType, data) {
  let postData = {};
  let url = config.riskAccesstoken;
  if (riskTicketType == 1) {
    url = config.ticketAccesstoken;
    postData.ticketId = data.ticketId;
  } else {
    postData.riskId = data.riskId;
  }
  return request({
    url: url,
    method: 'post',
    data: postData
  });
}

// 查询当前用户所属客户
export function getCompanyList(data, isIgnore) {
  return request({
    url: config.companyListCommonHttp,
    method: 'post',
    data: data
  });
}

// 查询所有销售
export function getSaleList(data, isIgnore) {
  return request({
    url: config.saleListHttp,
    method: 'post',
    data: data
  });
}

// 查询菜单列表
export function getInfrigementList(data, isIgnore) {
  return request({
    url: config.infringementHttp,
    method: 'post',
    data: data
  });
}
export function preCreateTicket(data) {
  return request({
    url: config.beforeCreateTicket,
    method: 'post',
    data
  });
}

//创建工单
export function createTicket(data, isEdit) {
  let url = config.createTicketByHandHttp;
  let noError = true;
  if (isEdit) {
    noError = false;
    if (data.status == 14) {
      url = config.ticketInnerUpdateHttp;
    } else {
      url = config.updateTicketHttp;
    }
  }
  return request({
    url: url,
    method: 'post',
    data: data,
    noError: noError
  });
}

// 查询平台
export function getPlatformList(data) {
  return request({
    url: config.platformListHttp,
    method: 'post',
    data: data
  });
}

// 查询平台
export function getStatisticalIndexList(data) {
  return request({
    url: config.getStatisticalIndexHttp,
    method: 'post',
    data: data
  });
}

// 获取行业
export function getIndustryList(data) {
  return request({
    url: config.industryListHttp,
    method: 'post',
    data: data
  });
}
export function getCustomerCompanyList(data) {
  return request({
    url: config.userBrandListInHomeHttp,
    method: 'post',
    data: data
  });
}

// 获取行业
export function getAllCompanyList(data, searchType) {
  let url = config.allCompanyBrandListHttp;
  if (searchType == 'riskDetect') {
    url = config.riskDetectAllCompanyBrandListHttp;
  }
  return request({
    url: url,
    method: 'post',
    data: data
  });
}