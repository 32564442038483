import request from '@/utils/request';
import config from '@/api/config';
export function getList(data) {
  return request({
    url: config.assetAdminListHttp,
    method: 'post',
    data: data
  });
}
export function countsData(data) {
  return request({
    url: config.assetAdminCountsHttp,
    method: 'post',
    data: data
  });
}
export function assetConfirm(data) {
  return request({
    url: config.assetAdminConfirmHttp,
    method: 'post',
    data: data
  });
}
export function assetIsImport(data) {
  return request({
    url: config.assetIsImport,
    method: 'post',
    data: data
  });
}

//合同客户列表
export function editAsset(data) {
  let url = config.assetAdminAddHttp;
  if (data.id) {
    delete data.id;
    url = config.assetAdminEditHttp;
  }
  return request({
    url: url,
    method: 'post',
    data: data
  });
}