const baseURL = process.env.NODE_ENV == 'development' ? '/dev-api/' : '/';
export default {
  baseURL: baseURL,
  session_login_key: 'isLogin',
  SESSION_BRANDS_LIST: 'sessionBrandsList',
  smallWidth: 1660,
  commentOssPath: 'CommentOss',
  loginHtml: '/login.html',
  //登录页面
  logoutHttp: 'logout',
  //登出

  //登录
  getPublicKeyHttp: 'user_getPublicKey.action',
  //登录前接口
  loginHttp: 'ajaxLogin.action',
  //登录

  beforeCreateTicket: 'json/V4/operate/ticket/pre-create.json',
  //创建工单前执行
  commonUploadHttp: baseURL + 'json/V3/operate/file/upload.json',
  //上传到后台然后传阿里云
  userInfoHttp: 'json/V3/operate/session-info.json',
  //获取用户信息
  changeCompanyHttp: 'json/V3/operate/switch-company.json',
  //切换客户
  riskBrandListHttp: 'json/V4/search/admin-user-brands.json',
  //风险探测获取品牌
  riskListInHomeHttp: 'json/V4/search/risks.json',
  //获取风险列表
  ticketListInHomeHttp: 'json/V4/search/tickets.json',
  //查询工单列表
  remarkUpdateHttp: 'json/V4/operate/ticket/saveTicketOperationInHome.json',
  //编辑备注
  dealRiskHttp: 'json/V4/operate/ticket/manageRiskAndCreateTicketInHome.json',
  //用户风险处置
  riskIngoreHttp: 'json/V4/operate/risk/ignoreRiskInHome.json',
  //风险忽略
  changeLocaleHttp: 'json/system/changeLocale.json',
  //切换中英文

  riskLevelReasonListHttp: 'json/V3/operate/risk-detect/modification-reasons.json',
  //风险等级原因

  riskDetailHttp: 'json/V4/search/get-risk.json',
  //获取风险详情

  saleListHttp: 'json/V3/search/contract/getSale.json',
  //获取所有销售
  serviceTypeAuthHttp: 'json/V4/search/subscribe-services.json',
  //获取有权限的各类型
  //TODO：合同
  contractCompanyListHttp: 'json/V3/search/contract/getCustom.json',
  //合同客户列表
  contractListHttp: 'json/V3/search/contract/getContract.json',
  //合同列表
  contractDetailHttp: 'json/V3/search/contract/getContractById.json',
  //合同详情
  contractAddHttp: 'json/V3/operate/contracts/addContract.json',
  //合同新增
  contractUpdateHttp: 'json/V3/operate/contracts/updateContract.json',
  //合同编辑
  contractRenewHttp: 'json/V3/operate/contracts/editContract.json',
  //合同续约
  contractDeleteHttp: 'json/V3/operate/contracts/deleteContract.json',
  //合同删除
  contractUploadHttp: baseURL + 'json/V3/operate/contracts/upload.json',
  //合同扫描件
  contractAuthLastHttp: 'json/V3/search/letter/getLatestAuthorizationLetter.json',
  //查询最新授权函信息
  contractAuthHttp: 'json/V3/search/letter/getAuthorizationLetter.json',
  //获取合同授权函
  contractAuthAddHttp: 'json/V3/operate/authorization/addAuthorization.json',
  //合同授权函提交
  contractAuthDownLoadHttp: baseURL + 'json/V3/operate/authorization/downloadAuthorizationLetter.json',
  //授权函下载
  authDeleteHttp: '/json/V3/operate/authorization/deleteAuthorizationLetter.json',
  //

  //TODO:资产管理
  assetAdminListHttp: 'json/V3/operate/brand-assets/list.json',
  //列表
  assetAdminAddHttp: 'json/V3/operate/brand-assets/add.json',
  //列表
  assetAdminEditHttp: 'json/V3/operate/brand-assets/update.json',
  //列表
  assetAdminUploadHttp: baseURL + 'json/V3/operate/brand-assets/upload.json',
  //列表
  assetAdminConfirmHttp: 'json/V3/operate/brand-assets/confirm-asset.json',
  //列表
  assetAdminExportHttp: baseURL + 'json/V3/operate/brand-assets/export.json',
  //列表
  assetAdminCountsHttp: 'json/V3/operate/brand-assets/counts.json',
  //列表
  assetAdminImportHttp: 'json/V3/operate/brand-assets/import.json',
  //资产导入

  batchDealRiskHttp: 'json/V4/operate/ticket/batchManageRiskAndCreateTicketInHome.json',
  //批量处置风险
  batchIgnoreRiskHttp: 'json/V4/operate/risk/batchIgnoreRiskInHome.json',
  //批量忽略风险
  userCompanyBrandListInHomeHttp: 'json/V4/search/risks/brands-for-query.json',
  //首页获取用户的品牌列表
  userBrandListInHomeHttp: 'json/V4/search/brands/customer-brands.json',
  //获取用户的品牌列表
  // brandListForCreateTicketByHandHttp: 'json/V3/search/brandListForCreateTicketByHand.json',//获取品牌列表
  brandListForCreateTicketByHandHttp: 'json/V4/search/brands/customer-brands-for-create-ticket.json',
  //获取品牌列表
  aggregateRiskSearchParamHttp: 'json/V3/search/aggregateRiskSearchParam.json',
  //高级搜索待选项列表
  aggregateTicketSearchParamHttp: 'json/V3/search/aggregateTicketSearchParam.json',
  //高级搜索待选项列表
  orderReviewCountInTicketHttp: 'json/V4/search/orderReviewCountInTicket.json',
  //查询是否有待审核工单
  orderReviewListInTicketHttp: 'json/V4/search/orderReviewListInTicket.json',
  //获取待审核信息
  // creatorOnAmdinHttp: 'json/V3/search/creatorOnAmdin.json',//根据品牌列表的选择更新指定用户列表
  creatorOnAmdinHttp: 'json/V4/search/customers/who-can-handle-this-type-of-risk.json',
  //根据品牌列表的选择更新指定用户列表
  checkWeChatUserHttp: 'json/V3/search/checkWeChatUser.json',
  potentialRiskCountInHomeHttp: 'json/V3/search/potentialRiskCountInHome.json',
  //获取潜在风险数据统计结果
  potentialRiskListInHomeHttp: 'json/V3/search/potentialRiskListInHome.json',
  //获取潜在风险数据详情
  manageRiskCountInHomeHttp: 'json/V3/search/manageRiskCountInHome.json',
  //处置完成统计
  manageRiskListInHomeHttp: 'json/V3/search/manageRiskListInHome.json',
  //处置完成详情列表
  ignoreRiskCountInHomeHttp: 'json/V3/search/ignoreRiskCountInHome.json',
  //已忽略数据统计
  ignoreRiskListInHomeHttp: 'json/V3/search/ignoreRiskListInHome.json',
  //已忽略详情列表
  riskListInDetectHttp: 'json/V4/search/riskListInDetect.json',
  //获取列表数据
  riskDetectDetailHttp: 'json/V4/search/detect/get-risk.json',
  //获取风险详情
  previousRiskInDetectHttp: 'json/V4/search/previousRiskInDetect.json',
  //获取上一条风险详情
  nextRiskInDetectHttp: 'json/V4/search/nextRiskInDetect.json',
  //获取下一条风险详情
  submNextRiskInDetectHttp: 'json/V3/search/submNextRiskInDetect.json',
  exportRiskListInDetectHttp: 'json/V4/search/exportRiskListInDetect.json',
  //风险列表导出功能
  // exportRiskListInDetectHttp: 'json/risk/exportRiskListInDetect.json',//风险列表导出功能
  riskCountByBrandPriorityInDetectHttp: 'json/V4/search/riskCountByBrandPriorityInDetect.json',
  //获取各品牌类型的数量

  manageOwnTicketCountInHomeHttp: 'json/V3/search/manageOwnTicketCountInHome.json',
  //处置完成统计
  manageOwnTicketListInHomeHttp: 'json/V3/search/manageOwnTicketListInHome.json',
  //处置完成详情列表
  manageTicketListInProcessHttp: 'json/V3/search/manageTicketListInProcess.json',
  //获取或更新监控处置数据列表
  userBrandListInProcessHttp: 'json/V3/search/userBrandListInProcess.json',
  //获取用户的品牌列表
  userServiceListInProcessHttp: 'json/V3/search/userServiceListInProcess.json',
  //获取用户风险类型列表
  userServiceListInHomeHttp: 'json/V3/search/userServiceListInHome.json',
  //获取用户风险类型列表

  uploadEvidence: 'json/V4/operate/ticket/uploadEvidence.json',
  //上传举证截图
  uploadLeakageContent: 'json/V4/operate/ticket/uploadLeakageContent.json',
  //上传泄露截图
  uploadSupplementHttp: baseURL + 'json/V4/operate/ticket/uploadSupplementalMaterial.json',
  //上传补充材料
  uploadAppHttp: baseURL + 'json/V4/operate/ticket/uploadFalseReportApp.json',
  //上传app
  uploadAuthFileHttp: baseURL + 'json/V4/operate/ticket/uploadAuthorizationFile.json',
  //上传授权文件

  uploadEvidenceInDetectHttp: 'json/V3/operate/risk-detect/uploadEvidenceInDetect.json',
  //风险探测举证截图上传文件
  uploadLeakageInDetectHttp: 'json/V3/operate/risk-detect/uploadLeakageContent.json',
  //风险探测泄露截图上传文件

  tagListHttp: 'json/V4/search/risk-tags/default.json',
  //标签列表
  addTagHttp: 'json/V4/operate/ticket/addTagListInHome.json',
  //标签列表
  userTagListHttp: 'json/V4/search/userTagListInHome.json',
  //默认标签
  saveRiskTagInDetectHttp: 'json/V4/operate/ticket/saveWorkOrderTagInTicket.json',
  //保存标签
  changeTicketStatusByHandHttp: 'json/V4/operate/ticket/changeTicketStatusByHand.json',
  //保存工单状态
  docReceivedHttp: baseURL + 'json/V4/operate/ticket/docReceived.json',
  //上传确认单

  supplyPauseTicketHttp: 'json/V4/operate/ticket/proceedPauseTicket.json',
  //补充材料提交
  updateTicketHttp: 'json/V4/operate/ticket/updateTicket.json',
  //修改工单提交数据
  createTicketByHandHttp: 'json/V4/operate/ticket/createTicketByHand.json',
  //创建工单提交数据
  workOrderReviewInTicketHttp: 'json/V4/operate/ticket/workOrderReviewInTicket.json',
  //审核通过
  reopenTicketInHomeHttp: 'json/V4/operate/ticket/reopenTicketInHome.json',
  //工单重开
  riskConfirmInDetectHttp: 'json/V3/operate/risk-detect/confirm.json',
  //风险处置确认提交
  darkRiskConfirmInDetectHttp: 'json/V3/operate/risk-detect/dark-web/confirm.json',
  //暗网风险处置确认提交
  cancelTicketHttp: 'json/V4/operate/ticket/cancelTicket.json',
  //撤销工单回调

  getReportTemplateListHttp: 'json/V3/search/report/getReportTemplateList.json',
  //统计报表-报表列表
  getReportFormseListHttp: 'json/V3/search/report/word-reports.json',
  //统计报告列表获取
  ignoreRiskInDetectHttp: 'json/V3/operate/risk-detect/ignore.json',
  //风险忽略
  darkIgnoreDarkRiskInDetectHttp: 'json/V3/operate/risk-detect/dark-web/ignore.json',
  //暗网风险忽略
  riskRawListInDetectHttp: 'json/V4/search/detect/riskRawListInDetect.json',
  //风险探测详情
  riskDetectBatchDealHttp: 'json/V3/operate/risk-detect/batch-confirm.json',
  //风险探测批量确认
  riskDetectBatchIgnoreHttp: 'json/V3/operate/risk-detect/batch-ignore.json',
  //风险探测批量忽略
  darkRiskDetectBatchIgnoreHttp: 'json/V3/operate/risk-detect/dark-web/batch-ignore.json',
  //暗网风险探测批量忽略
  riskDetectUpdateHttp: 'json/V3/operate/risk-detect/update.json',
  //风险探测更新图片
  locationListHttp: 'json/V4/search/detect/locations.json',
  ///地理位置列表/

  getStatisticalIndexHttp: 'json/V3/search/report/getTemplate.json',
  //获取统计指标

  //白名单
  whiteListAddHttp: 'json/V3/operate/risk-detect/add-auto-confirm-config.json',
  //添加白名单
  whiteListEditHttp: 'json/V3/operate/risk-detect/update-auto-confirm-config.json',
  //修改白名单
  whiteListDeleteHttp: 'json/V3/operate/risk-detect/delete-auto-confirm-config.json',
  //删除白名单
  whiteListHttp: 'json/V3/operate/risk-detect/query-auto-confirm-config.json',
  //白名单列表

  //定制报告
  customReportAddHttp: 'json/V3/operate/report/custom/task/create.json',
  //创建报告
  customReportListHttp: 'json/V3/search/report/custom/list.json',
  customReportTaskListHttp: 'json/V3/search/report/custom/task/list.json',
  customReportTaskChangeStatusHttp: 'json/V3/operate/report/custom/task/closeOrOpen.json',
  customReportTaskEditHttp: 'json/V3/operate/report/custom/task/update.json',
  customReportTaskDeleteHttp: 'json/V3/operate/report/custom/task/delete.json',
  customReportDeleteHttp: 'json/V3/operate/report/custom/delete.json',
  customReportReGenerateHttp: 'json/V3/operate/report/custom/regenerate.json',
  customReportDownloadHttp: baseURL + 'json/V3/search/report/custom/file/download.json',
  customReportDownloadCheckAuthHttp: 'json/V3/search/report/custom/file/download/permission/check.json',
  //报告
  reportCreateHttp: 'json/V3/search/report/word-reports/create.json',
  //创建报告
  reportUpdateHttp: 'json/V3/search/report/word-reports/update.json',
  //修改报告
  reportDeleteHttp: 'json/V3/search/report/word-reports/delete.json',
  //删除报告
  reportDownloadHttp: baseURL + 'json/V3/search/report/word-reports/download.json',
  //报告下载
  reportDetailHttp: 'json/V3/search/report/word-reports/get.json',
  //获取报告详情
  //诊断报告
  diagnosisReportListHttp: 'json/V3/search/report/get-report.json',
  //获取诊断报告列表
  diagnosisReportDetailHttp: 'json/V3/search/report/getReportById.json',
  //获取诊断报告详情
  diagnosisReportEditHttp: 'json/V3/operate/diagnosisReport/addOrUpdateDiagnosisReport.json',
  //添加诊断报告
  diagnosisReportDeleteHttp: 'json/V3/operate/diagnosisReport/deleteDiagnosisReport.json',
  //删除诊断报告
  diagnosisReportDownloadHttp: baseURL + '/json/V3/operate/diagnosisReport/download.json',
  //下载诊断报告
  diagnosisReportUploadHttp: baseURL + 'json/V3/operate/diagnosisReport/upload.json',
  //上传诊断报告
  diagnosisReportPublishHttp: '/json/V3/operate/diagnosisReport/releaseDiagnosisReport.json',
  //发布诊断报告

  //报表
  addReportTemplateHttp: 'json/V4/search/report/table-reports/create.json',
  //统计报表-新增报表
  updateReportTemplateHttp: 'json/V4/search/report/table-reports/update.json',
  //统计报表-新增报表
  reportFormDeleteHttp: 'json/V4/search/report/table-reports/deleteReportTemplate.json',
  //报表删除
  getReportFormDetailHttp: 'json/V3/search/report/getReportTemplateById.json',
  //获取报表详情
  reportFormDownloadHttp: 'json/V4/search/report/table-reports/downloadTemplateFile.json',
  //报表下载
  reportFormGetTemplateHttp: 'json/V4/search/report/table-reports/checkGenerateTemplate.json',
  //查看报表获取数据url
  //数字风险评估报告
  listDigitalRiskReportHttp: 'json/V4/search/assessment-report/tasks.json',
  addDigitalRiskReportHttp: 'json/V4/search/assessment-report/new-task.json',
  runDigitalRiskReportHttp: 'json/V4/search/assessment-report/run-task.json',
  downloadDigitalRiskReportHttp: 'json/V4/search/assessment-report/download-url.json',
  uploadDigitalRiskReportHttp: baseURL + 'json/V4/search/assessment-report/upload.json',
  //消息盒子
  messageUnreadCountHttp: 'json/V4/operate/msg-center/get-not-read-comment-and-msg-count.json',
  //未读消息数量
  messageListHttp: 'json/V4/operate/msg-center/query-user-msg.json',
  //获取消息列表
  messageStatusHttp: 'json/V4/operate/msg-center/update-user-msg-status',
  //改变消息装填
  messageAllReadedHttp: 'json/V4/operate/msg-center/read-all-msg',
  //所有消息已读

  msgCommentListHttp: 'json/V4/operate/msg-center/get-comments.json',
  //获取评论列表
  msgCommentStatusHttp: 'json/V4/operate/msg-center/change-comment-read-status.json',
  //获取评论列表
  msgCommentAllReadedHttp: 'json/V4/operate/msg-center/change-all-comment-read-status.json',
  //获取评论列表

  //工作台
  workPortalTicketCountHttp: 'json/V4/search/workbench/ticket-count.json',
  //工单数量统计
  workPortalDayilyRiskCountHttp: 'json/V4/search/workbench/daily-risk-count.json',
  //每日新增风险
  workPortalDayilyTicketCountHttp: 'json/V4/search/workbench/daily-ticket-count.json',
  //每日新增工单
  workPortalRiskToTicketPercentHttp: 'json/V4/search/workbench/risk-to-ticket-rate.json',
  //工单转化率
  workPortalRiskLocationHttp: 'json/V4/search/workbench/risk-count-by-location.json',
  //风险地区分布
  workPortalSuccessedCompanyRankHttp: 'json/V4/search/workbench/finished-ticket-count-by-carrier.json',
  //处置成功服务商排名
  workPortalClosedCompanyRankHttp: 'json/V4/search/workbench/closed-ticket-count-by-carrier.json',
  //关闭工单服务商排名
  workPortalRiskRankHttp: 'json/V4/search/workbench/risk-count-ranking.json',
  //风险数据排名
  workPortalTicketDealTimeMinMaxHttp: 'json/V4/search/workbench/finished-ticket-max-and-min-time-cost.json',
  //工单处置市场最大、最小值
  workPortalTicketDealTimeMedianHttp: 'json/V4/search/workbench/finished-ticket-median-time-cost.json',
  //工单处置时长中位数
  workPortalTicketWarningTimeMinMaxHttp: 'json/V4/search/workbench/processing-ticket-max-and-min-time-cost.json',
  //工单处置市场最大、最小值
  workPortalTicketWarningTimeMedianHttp: 'json/V4/search/workbench/processing-ticket-median-time-cost.json',
  //工单预警时长中位数
  workPortalServiceAdminHttp: 'json/V4/search/workbench/service-statuses.json',
  //服务管理
  workPortalReContractHttp: 'json/V4/search/workbench/customer-renewal-rate.json',
  //续约率
  workPortalRenewHttp: 'json/V4/search/workbench/revenue-renewal-rate.json',
  //续费率

  allCompanyBrandListHttp: 'json/V4/search/workbench/customer-and-brands.json',
  //获取所有客户品牌列表
  riskDetectAllCompanyBrandListHttp: 'json/V4/search/detect/customer-and-brands.json',
  //获取所有客户品牌列表-风险探测
  brandListFromCompanyhttp: 'json/V3/search/companyBrandList.json',
  //运营端获取所有客户内得品牌
  industryListHttp: 'json/V4/search/workbench/industries.json',
  //获取行业列表

  //风险总览
  portalUserIndustBrandListHttp: 'json/V3/search/user-portal/industry-and-brand.json',
  //portal用户：统计页面行业品牌列表
  portalUserRiskTypeListHttp: 'json/V3/search/user-portal/risk-service-info.json',
  //portal用户：用户风险再各个业务上的风险分布
  portalUserTicketTypeListHttp: 'json/V3/search/user-portal/ticket-service-info.json',
  //portal用户：工单再各个业务上的风险分布
  portalUserRiskTrendHttp: 'json/V3/search/user-portal/risk-trend-line.json',
  //portal用户：用户风险走势图
  portalUserTicketTypeHttp: 'json/V4/search/customer-portal/ticket-type-count.json',
  //portal用户：用户工单按类型统计
  portalUserRiskTypeHttp: 'json/V4/search/customer-portal/risk-type-count.json',
  //portal用户：用户风险按类型统计
  portalUserRiskAreaHttp: 'json/V4/search/customer-portal/risk-country-count.json',
  //portal用户：用户风险在各个国家的分布信息
  portalUserRiskLevelHttp: 'json/V4/search/customer-portal/risk-level-count.json',
  //portal用户：风险等级统计
  protalUserRiskTimeCountHttp: 'json/V4/search/customer-portal/risk-time-count.json',
  // 用户风险按时间统计
  protalUserRiskAddCountHttp: 'json/V4/search/customer-portal/risk-count.json',
  // 用户风险每日新增统计
  protalUserTicketTotalHttp: 'json/V4/search/customer-portal/ticket-status-count.json',
  // 工单各状态统计
  protalUserTicketMiddleTimeHttp: 'json/V4/search/customer-portal/ticket-time-cost.json',
  // 工单中位数时间
  protalUserServiceStatusHttp: 'json/V4/search/customer-portal/service-status.json',
  // 服务状态
  protalUserServiceRankHttp: 'json/V4/search/customer-portal/risk-carrier-count.json',
  // 网络服务商风险排名
  protalUserRiskToTicketHttp: 'json/V4/search/customer-portal/risk-to-ticket-sankey.json',
  // 风险工单转化桑基图
  protalUserRiskScoreHttp: 'json/V4/search/customer-portal/risk-score.json',
  // 风险值
  protalUserRiskFilterHttp: 'json/V4/search/customer-portal/risk-data-filtering-count.json',
  // 风险过滤统计

  portalAdminIndustBrandListHttp: 'json/V4/search/admin-portal/industry-and-brand.json',
  //portal用户：统计页面行业品牌列表
  portalAdminRiskTypeListHttp: 'json/V4/search/admin-portal/risk-service-info.json',
  //portal用户：用户风险再各个业务上的风险分布
  portalAdminTicketTypeListHttp: 'json/V4/search/admin-portal/ticket-service-info.json',
  //portal用户：工单再各个业务上的风险分布
  protalAdminTicketTime: 'json/V4/search/admin-portal/ticket-time-cost-info.json',
  //protal管理员：工单处置用时统计
  protalAdminTicketStatusNum: 'json/V4/search/admin-portal/ticket-status-info.json',
  //protal管理员：工单状态分布信息
  protalAdminRiskThrendHttp: 'json/V4/search/admin-portal/industry-risk-trend-line.json',
  //protal管理员： 行业风险走势图
  protalAdminRiskLocationHttp: 'json/V4/search/admin-portal/risk-location-trend-line.json',
  //protal管理员： 风险位置分布走势图
  protalAdminTicketTrendHttp: 'json/V4/search/admin-portal/industry-ticket-trend-line.json',
  //protal管理员： 行业工单走势图
  protalAdminBrandRankHttp: 'json/V4/search/admin-portal/brand-risk-rank.json',
  //protal管理员： 品牌风险排名
  protalAdminRiskLevelHttp: 'json/V4/search/admin-portal/risk-level.json',
  //protal管理员： 获取管理员风险值
  ticketAccesstoken: 'json/V4/search/ticket/access-token.json',
  //获取工单图片的参数
  riskAccesstoken: 'json/V4/search/risk/access-token.json',
  //获取工单图片的参数

  operationListHttp: 'json/V4/search/ticketOperationListInHome.json',
  //操作记录
  ticketCopyHttp: 'json/V4/operate/ticket/copyTicket.json',
  //已完成工单复制获取数据接口
  riskGetScreenShotHttp: 'json/V3/operate/file/getScreenshot.json',
  //风险探测获取实时预览截图

  infringementHttp: 'json/V4/search/brands/resources.json',
  //获取侵权项列表
  ticketInnerRejectHttp: 'json/V4/operate/ticket/workOrderInnerRejectInTicket.json',
  //工单内部审核拒绝
  ticketInnerReviewHttp: 'json/V4/operate/ticket/workOrderInnerReviewInTicket.json',
  //工单内部审核通过
  ticketInnerUpdateHttp: 'json/V4/operate/ticket/updateInnerTicket.json',
  //工单内部审核驳回 修改

  ticketDocDownloadHttp: 'json/V3/operate/ticket/getTicketDoc.json',
  //工单确认单下载
  reGetTicketDocHttp: 'json/V3/operate/ticket/reGetTicketDoc.json',
  //重新获取工单确认单

  ticketMonitorListHttp: 'json/V4/search/websiteMonitorListInHome.json',
  //工单网站监控
  riskMonitorListHttp: 'json/V4/operate/risk/getRiskMonitorResult.json',
  //风险验活结果
  riskMonitorStatusHttp: 'json/V4/operate/risk/getRiskMonitorStatus.json',
  //获取风险焰火状态状态
  riskMonitorAgainHttp: 'json/V4/operate/risk/judgeRiskSurvival.json',
  //执行验活操作

  pauseTicketHttp: 'json/V4/operate/ticket/pauseTicket.json',
  //暂停工单
  workOrderRejectInTicketHttp: 'json/V4/operate/ticket/workOrderRejectInTicket.json',
  tagTabListInHomeHttp: 'json/V4/search/risk-tags/default.json',
  //获取预设标签
  addTagListInHomeHttp: 'json/V4/operate/ticket/addTagListInHome.json',
  //添加标签

  /************   评论   **************/
  commentListHttp: 'json/V3/search/comment/getCommentAndReply.json',
  //查看评论
  commentAddHttp: 'json/V3/operate/comment/commentAndReplyTicketOrRisk.json',
  //评论
  commentDelHttp: 'json/V3/operate/comment/deleteComment.json',
  //删除评论
  commentUnReadCountHttp: 'json/V3/search/comment/getUnreadCommentCount.json',
  //评论未读数量
  /************   服务管理-日志列表   **************/
  logListHttp: 'json/V3/search/log/getLog.json',
  //日志列表
  moduleListHttp: 'json/V3/search/general/getModule.json',
  //日志列表
  /************   服务管理-Api列表   **************/
  apiListHttp: 'json/V4/search/drpapi/getAPIKey.json',
  //api列表
  apiAddHttp: 'json/V3/operate/company/insertAPIKey.json',
  //api新增
  apiUpdateHttp: 'json/V3/operate/company/updateAPIKey.json',
  //api修改
  getModuleListHttp: 'json/V3/search/general/getModule.json',
  //获取模块列表
  /************   用户列表   **************/
  inviteUserHttp: 'json/V3/operate/companies/createCompanyInviteCode.json',
  //邀请新用户
  userListHttp: 'json/V4/search/tickets/customers-for-create-ticket.json',
  //用户列表
  userPermissionSaveHttp: 'json/V3/operate/user/save-user-permission.json',
  //用户新增分配权限
  userPermissionGetHttp: 'json/V3/operate/user/list-user-permission.json',
  //获取用户客户权限
  userUpdateStatusHttp: 'json/V3/operate/user/update-user-status.json',
  //修改用户状态
  userCompanyHttp: 'json/V3/operate/user/add-company.json',
  //用户关联客户
  userRoleTemplateListHttp: 'json/V3/operate/user/query-role-template.json',
  //用户对应角色模板
  userDelPermissionHttp: 'json/V3/operate/user/del-user-permission.json',
  //删除用户权限信息
  /************   角色相关   **************/
  rolePermissionTemplateListHttp: 'json/V3/operate/user/query-permission-template.json',
  //角色对应权限模板

  /************   客户相关   **************/
  riskTicketCompanyListHttp: 'json/V4/search/risks/companies-for-query.json',
  //风险工单相关的客户列表
  companyListHttp: 'json/V3/search/company/getCompanyAccount.json',
  //客戶列表
  companyListCommonHttp: 'json/V3/search/company/getCompanyAccountCommon.json',
  //客戶列表-所有
  userCompanyListHttp: 'json/V3/operate/companies/user-companies.json',
  //客戶列表-所有
  companyServiceListHttp: 'json/V3/operate/user/query-all-pack-services-by-company.json',
  //客户关联的所有服务包业务类型去重集合
  companyBrandTreeList: 'json/V3/search/service-auth/list-company-with-brands.json',
  //客户品牌树
  companyAuthFileListHttp: 'json/V3/search/service-auth/list-auth-files.json',
  //客户授权文件
  companyManagerInfoHttp: 'json/V3/search/service-auth/query-company-manager.json',
  //查询客户的客户经理
  companySaveAuthFileHttp: 'json/V3/operate/service-auth/save-company-auth-file.json',
  //客户保存授权文件
  companyDelAuthFileHttp: 'json/V3/operate/service-auth/delete-company-auth-file.json',
  //删除客户授权文件
  /************   品牌相关   **************/
  brandServiceInfoHttp: 'json/V3/search/service-auth/query-pack-detail-info.json',
  //品牌
  /************   资产管理   **************/
  dictDataHttp: 'json/V3/operate/dict/list.json',
  //字典
  platformListHttp: 'json/V3/operate/platforms.json',
  //平台列表
  assetImportFile: 'json/V3/operate/company-asset-collect/create-from-aiqicha.json',
  //资产导入文件
  assetImportTaskList: 'json/V3/operate/company-asset-collect/tasks.json',
  //资产导入列表
  assetImportConfirmHttp: 'json/V3/operate/company-assets/confirm-assets.json',
  //资产导入确认

  assetIsImport: 'json/V3/operate/brand-assets/is-importing.json',
  //确定是否有当前品牌导入
  //关键词
  keywordListHttp: 'json/V3/operate/company-assets/list-keyword.json',
  //关键词获取
  keywordPutHttp: 'json/V3/operate/company-assets/put-keyword.json',
  //关键词更新

  brandInfoHttp: 'json/V3/operate/brands/brand-info.json',
  //品牌信息
  searchSuggestHttp: 'json/V3/operate/company-assets/query-suggestions.json',
  //通过品牌模糊查询客户、品牌

  queryByAssetHttp: 'json/V3/operate/company-assets/query-by-condition.json',
  //通过关键词模糊查询指定类型所有资产
  //商标信息
  trademarkAddHttp: 'json/V3/operate/company-assets/post-trademark.json',
  //增加商标
  trademarkEditHttp: 'json/V3/operate/company-assets/put-trademark.json',
  //修改商标
  trademarkListHttp: 'json/V3/operate/company-assets/list-trademark.json',
  //商标列表
  trademarkDelHttp: 'json/V3/operate/company-assets/del-trademark.json',
  //商标列表
  trademarkDetailHttp: 'json/V3/operate/company-assets/get-trademark.json',
  //商标详情
  //作品著作权-作品著作权
  workCopyRightAddHttp: 'json/V3/operate/company-assets/post-copyright.json',
  //作品著作权新增
  workCopyRightEditHttp: 'json/V3/operate/company-assets/put-copyright.json',
  //作品著作权修改
  workCopyRightListHttp: 'json/V3/operate/company-assets/list-copyright.json',
  //作品著作权列表
  workCopyRightDelHttp: 'json/V3/operate/company-assets/del-copyright.json',
  //作品著作权删除
  workCopyRightDetailHttp: 'json/V3/operate/company-assets/get-copyright.json',
  //作品著作权详情
  //作品著作权-影视
  moviesAddHttp: 'json/V3/operate/company-assets/post-film-and-television-copyright.json',
  //影视创建
  moviesEditHttp: 'json/V3/operate/company-assets/put-film-and-television-copyright.json',
  //影视修改
  moviesListHttp: 'json/V3/operate/company-assets/list-film-and-television-copyright.json',
  //影视列表
  moviesDelHttp: 'json/V3/operate/company-assets/del-film-and-television-copyright.json',
  //影视删除
  moviesDetailHttp: 'json/V3/operate/company-assets/get-film-and-television-copyright.json',
  //影视详情
  //软件著作权
  softwareAddHttp: 'json/V3/operate/company-assets/post-software-copyright.json',
  //创建软件著作权
  softwareEditHttp: 'json/V3/operate/company-assets/put-software-copyright.json',
  //修改软件著作权
  softwareListHttp: 'json/V3/operate/company-assets/list-software-copyright.json',
  //软件著作权列表
  softwareDelHttp: 'json/V3/operate/company-assets/del-software-copyright.json',
  //删除软件著作权
  softwareDetailHttp: 'json/V3/operate/company-assets/get-software-copyright.json',
  //软件著作权详情
  //专利信息
  patentInfoAddHttp: 'json/V3/operate/company-assets/post-patent.json',
  //专利信息创建
  patentInfoEditHttp: 'json/V3/operate/company-assets/put-patent.json',
  //专利信息修改
  patentInfoListHttp: 'json/V3/operate/company-assets/list-patent.json',
  //专利信息列表
  patentInfoDelHttp: 'json/V3/operate/company-assets/del-patent.json',
  //专利信息删除
  patentInfoDetailHttp: 'json/V3/operate/company-assets/get-patent.json',
  //专利信息详情
  //APP
  AndroidAddHttp: 'json/V3/operate/company-assets/post-android-app.json',
  //app新增
  AndroidEditHttp: 'json/V3/operate/company-assets/put-android-app.json',
  //app编辑
  IOSAddHttp: 'json/V3/operate/company-assets/post-ios-app.json',
  //app编辑
  IOSEditHttp: 'json/V3/operate/company-assets/put-ios-app.json',
  //app编辑
  appListHttp: 'json/V3/operate/company-assets/list-app.json',
  //app列表
  appDelHttp: 'json/V3/operate/company-assets/del-app.json',
  //app删除
  appDetailHttp: 'json/V3/operate/company-assets/get-app.json',
  //app详情
  appHistoryListHttp: 'json/V3/operate/company-assets/list-history-app.json',
  //app历史版本
  iosInfoHttp: 'json/V3/operate/company-assets/ios-app-info.json',
  //获取ios信息
  //社交媒体账号
  socialMediaAddHttp: 'json/V3/operate/company-assets/post-social-account.json',
  //社媒账号新增
  socialMediaEditHttp: 'json/V3/operate/company-assets/put-social-account.json',
  //社媒账号编辑
  socialMediaListHttp: 'json/V3/operate/company-assets/list-social-account.json',
  //社媒账号列表
  socialMediaDelHttp: 'json/V3/operate/company-assets/del-social-account.json',
  //社媒账号删除
  socialMediaDetailHttp: 'json/V3/operate/company-assets/get-social-account.json',
  //社媒账号详情
  //域名
  ownDomainAddHttp: 'json/V3/operate/company-assets/post-self-domain-name.json',
  //自有域名新增
  followDomainAddHttp: 'json/V3/operate/company-assets/post-concern-domain-name.json',
  //关注域名新增
  domainEditHttp: 'json/V3/operate/company-assets/put-domain-name.json',
  //域名新增
  domainListHttp: 'json/V3/operate/company-assets/list-domain-name.json',
  //域名列表
  domainDelHttp: 'json/V3/operate/company-assets/del-domain-name.json',
  //域名删除
  domainDetailHttp: 'json/V3/operate/company-assets/get-domain-name.json',
  //域名详情
  //文档-文档
  documentAddHttp: 'json/V3/operate/company-assets/post-document.json',
  //文档新增
  documentEditHttp: 'json/V3/operate/company-assets/put-document.json',
  //文档编辑
  documentListHttp: 'json/V3/operate/company-assets/list-document.json',
  //文档列表
  documentDelHttp: 'json/V3/operate/company-assets/del-document.json',
  //文档删除
  documentDetailHttp: 'json/V3/operate/company-assets/get-document.json',
  //文档详情
  //所有权声明函
  ownershipAddHttp: 'json/V3/operate/company-assets/post-declaration-letter.json',
  //所有权声明函新增
  ownershipEditHttp: 'json/V3/operate/company-assets/put-declaration-letter.json',
  //所有权声明函编辑
  ownershipListHttp: 'json/V3/operate/company-assets/list-declaration-letter.json',
  //所有权声明函列表
  ownershipDelHttp: 'json/V3/operate/company-assets/del-declaration-letter.json',
  //所有权声明函删除
  ownershipDetailHttp: 'json/V3/operate/company-assets/get-declaration-letter.json',
  //所有权声明函详情
  //代码-代码模板
  codeTemplateAddHttp: 'json/V3/operate/company-assets/post-source-code.json',
  //代码模板新增
  codeTemplateEditHttp: 'json/V3/operate/company-assets/put-source-code.json',
  //代码模板编辑
  codeTemplateListHttp: 'json/V3/operate/company-assets/list-source-code.json',
  //代码模板列表
  codeTemplateDelHttp: 'json/V3/operate/company-assets/del-source-code.json',
  //代码模板删除
  codeTemplateDetailHttp: 'json/V3/operate/company-assets/get-source-code.json',
  //代码模板详情
  //IP
  ipAddHttp: 'json/V3/operate/company-assets/post-ip.json',
  //IP新增
  ipEditHttp: 'json/V3/operate/company-assets/put-ip.json',
  //IP编辑
  ipListHttp: 'json/V3/operate/company-assets/list-ip.json',
  //IP列表
  ipDelHttp: 'json/V3/operate/company-assets/del-ip.json',
  //IP删除
  ipDetailHttp: 'json/V3/operate/company-assets/get-ip.json',
  //IP详情
  //基础设施
  infrastructureAddHttp: 'json/V3/operate/company-assets/post-infrastructures.json',
  //基础设施新增
  infrastructureEditHttp: 'json/V3/operate/company-assets/put-infrastructure.json',
  //基础设施编辑
  infrastructureListHttp: 'json/V3/operate/company-assets/list-infrastructure.json',
  //基础设施列表
  infrastructureDelHttp: 'json/V3/operate/company-assets/del-infrastructure.json',
  //基础设施删除
  infrastructureDictHttp: 'json/V3/operate/company-assets/query-infrastructure-infos.json',
  //基础设施字典
  infrastructureDetailHttp: 'json/V3/operate/company-assets/get-infrastructure.json',
  //基础设施详情ge

  //个人中心
  getUserHttp: 'json/V3/search/userBasicInfo/getUser.json',
  //基础信息
  saveNameHttp: 'json/V3/operate/userBasicInfo/modifyNickname.json',
  //基础信息
  savemodifyHeadPortraitHttp: baseURL + 'json/V3/operate/userBasicInfo/modifyHeadPortrait.json',
  //头像信息
  getVerificationCodetHttp: 'json/V3/operate/userBasicInfo/getVerificationCode.json',
  //获取验证码
  changeTelphoneHttp: 'json/V3/operate/userBasicInfo/changeTelphone.json',
  //重新绑定手机号
  codeImg: baseURL + 'json/V3/operate/userBasicInfo/getCaptchaImage.json',
  //获取验证码
  updatePasswordhttp: 'json/V3/operate/userBasicInfo/updatePassword.json',
  //修改密码
  tokenHttp: 'json/V3/search/userBasicInfo/access-token.json',
  //获取token

  //通知设置
  msgtypelistHttp: 'json/V4/operate/msg-center/get-msg-type-list-with-checked.json',
  //查询通知
  getWeiXinQRcodeHttp: 'json/weixin/getWeiXinQRcode.json',
  //获取二维码
  unbindWeiXinUsersHttp: 'json/V4/operate/msg-center/unbind-wechat-user.json',
  //解绑
  getbindstatusHttp: 'json/V4/operate/msg-center/get-bind-status.json',
  // 查询微信绑定状态
  getusermsgconfiglistHttp: 'json/V4/operate/msg-center/get-user-msg-config-status.json',
  // 获取配置项状态
  saveconfiglistHttp: 'json/V4/operate/msg-center/save-user-msg-config.json',
  // 保存配置状态
  // getuserswechartHttp:'json/weixin/getWeiXinUsers.json'//暂时不用

  //我的账单
  companyBrandListHttp: 'json/V3/search/companyBrandList.json',
  //获取客户和品牌信息
  consumeRecordsHttp: 'json/V3/search/consumeRecords.json',
  //消费明细
  userServiceListInHome: 'json/V3/search/userServiceListInHome.json',
  //服务场景
  consumeRecordsExcel: 'json/V3/search/consumeRecordsExcel.json',
  //下载明细
  getChargeServiceHttp: 'json/V3/search/getChargeService.json',
  //客户明细
  brandIndustryListHttp: 'json/V3/search/brandIndustryList.json',
  //品牌行业
  getRecordTransToOSSHttp: 'json/V3/operate/companymonitor/getRecordTransToOSS.json',
  //查询监测报表
  delMonitorRecordHttp: 'json/V3/operate/companymonitor/delMonitorRecord.json',
  //删除监测报表
  companymonitoruploadHttp: baseURL + 'json/V3/operate/companymonitor/upload.json',
  //上传报表
  //系统管理业务端
  businessSystemManagerUserListHttp: 'json/V3/operate/user/list-user.json',
  //人员管理数据列表
  systemManagerchangeStatusHttp: 'json/V3/operate/user/update-user-status.json',
  //人员管理更改启用状态
  systemManagerUserSurplusNumber: 'json/V3/operate/user/query-disposal-times.json',
  //查询剩余处置次数
  systemManagerOperaterUserSurplusNumber: '/json/V4/search/customer-manage/query-disposal-times.json',
  //查询剩余处置次数
  systemManagerUserDisposeAllocationHttp: 'json/V3/operate/user/disposal-allocation.json',
  //处置分配提交地址
  systemManagerUserRoleListHttp: 'json/V3/operate/user/query-role-template.json',
  //处置分配提交地址
  systemManagerUserPermissiontHttp: 'json/V3/operate/user/list-user-permission.json',
  //权限配置中，人员权限的集合
  systemManagerUserPermissiontOptionHttp: 'json/V3/operate/user/query-permission-template.json',
  //权限配置中，权限模板待选项
  systemManagerBrandListHttp: 'json/V3/operate/companies/brands.json',
  //获取品牌列表
  systemManagerSetBrandServiceHttp: "json/V3/operate/user/query-all-pack-services-by-company.json",
  //权限配置-品牌配置-服务待选项
  systemManagerRolePremissionViewHttp: 'json/V4/search/customer-role-manage/query-permissions-by-role.json',
  //角色管理-查看权限
  newSystemManagerRolePremissionViewHttp: 'json/V4/search/biz-protection/role-manage/query-permissions-by-role.json',
  //角色管理-查看权限
  newSystemManagerCustomerPremissionViewHttp: 'json/V4/search/customer-role-manage/query-role-option-permissions.json',
  //角色管理-查看权限
  systemManagerRolePermisionOptionsHttp: 'json/V4/search/customer-role-manage/query-role-permission-template.json',
  //角色管理创建角色权限待选项列表
  newSystemManagerRolePermisionOptionsHttp: 'json/V4/search/biz-protection/role-manage/query-role-permission-template.json',
  //角色管理创建角色权限待选项列表
  newSystemManagerStaffPermisionOptionsHttp: '/json/V4/search/biz-protection/role-manage/query-role-option-permissions.json',
  //人员管理-权限配置-已有权限
  createInviteLinkHttp: 'json/V3/operate/companies/createCompanyInviteCode.json',
  //生成邀请链接
  sendInviteToEmailHttp: 'json/V3/operate/companies/sendEmailInvite.json',
  //通过邮箱邀请注册
  systemManagerAddRoleHttp: 'json/V4/operate/customer-role-manage/save-role-permission.json',
  //业务保护端角色新增或修改
  newSystemManagerAddRoleHttp: 'json/V4/operate/biz-protection/role-manage/save-role-permission.json',
  //业务保护端角色新增或修改
  businessSystemManagerRoleListHttp: 'json/V4/search/customer-role-manage/list-roles.json',
  //获取角色列表
  newBusinessSystemManagerRoleListHttp: 'json/V4/search/biz-protection/role-manage/list-roles.json',
  //获取角色列表
  newBusinessSystemManagerRoleOptionstHttp: 'json/V4/search/customer-role-manage/query-role-options-in-company.json',
  //获取角色列表
  newBusinessSystemManagerStaffOptionstHttp: 'json/V4/search/biz-protection/role-manage/query-role-options-in-company.json',
  //人员管理-权限配置-角色列表

  //系统管理运营端
  operationSystemManagerPositionHttp: 'json/V4/search/staff-manage/query-position-options.json',
  //员工管理的岗位列表
  operationStaffRoleListHttp: 'json/V4/search/staff-role-manage/list-roles.json',
  //获取角色列表
  operationStaffRoleListOptionHttp: '/json/V4/search/staff-role-manage/query-role-options.json',
  //获取角色列表
  operationStaffDataListHttp: 'json/V4/search/staff-manage/list-staff.json',
  //获取员工列表
  operationStaffChangeStatusHttp: 'json/V4/operate/stuff-manage/update-staff-status.json',
  //修改员工状态
  operationInheritOptionsHttp: 'json/V4/search/staff-manage/query-is-inherited-user-range.json',
  //查询可被继承的范围
  operationInheritSaveHttp: 'json/V4/operate/stuff-manage/add-is-inherited-user.json',
  //保存继承关系
  operationRoleManagerPermissionListHttp: 'json/V4/search/staff-role-manage/query-role-permission-template.json',
  //员工管理-角色管理-添加角色-获取权限集合待选项
  operationRoleManagerSaveCreateHttp: 'json/V4/operate/staff-role-manage/save-role-permission.json',
  //员工管理-角色管理-添加角色-提交保存地址
  operationRoleManagerQuerypermissionList: 'json/V4/search/staff-role-manage/query-permissions-by-role.json',
  //查询当前角色已有的权限列表
  operationCustomerManagerQuerypermissionList: '/json/V4/search/staff-role-manage/query-role-option-permissions.json',
  //查询运营端员工管理下权限配置中当前角色已有的权限列表
  operationCustomerStrayDataHttp: 'json/V4/search/customer-manage/query-all-users-with-company-info.json',
  //客户管理，游离客户获取地址
  operationClassCustomDataHttp: 'json/V4/search/customer-manage/query-all-companies-with-flag.json',
  //获取客户类别列表
  operationCustomerAllDataHttp: 'json/V4/search/customer-manage/query-user-in-company.json',
  //客户管理下查询客户下所有的人员数据
  operationCustomerAssignedCustomerHttp: 'json/V4/operate/customer-manage/assign-customer.json',
  //分配客户
  operationCustomerChangeStatusHttp: 'json/V4/operate/customer-manage/update-user-status.json',
  //更改人员状态
  operationCustomerHandlesNumberHttp: 'json/V4/operate/customer-manage/disposal-allocation.json',
  //分配处置次数
  operationStaffSavePermissionHttp: 'json/V4/operate/stuff-manage/save-staff-role-permission.json',
  //员工管理，权限配置-保存
  operationCustomerPermissionHttp: 'json/V4/search/customer-manage/list-user-permission.json',
  //客户管理-角色配置-获取默认权限
  operationCustomerSasveUserPermission: 'json/V4/operate/customer-manage/save-user-permission.json',
  //保存客户下的人员权限配置
  /**
   * 服务管理
   */
  serviceManagementCustomerListHttp: 'json/V4/search/customer-allocation/list-company.json',
  queryAllStaffDataHttp: 'json/V4/search/customer-allocation/list-all-staff.json',
  //获取所有员工
  saveSetCustomerInfoHttp: 'json/V4/operate/customer-allocation/allocate-customer.json',
  //保存设置信息
  //品牌映射
  brandMapDataListHttp: 'json/V4/search/brand-mapping/list.json',
  //获取品牌映射列表
  addNewBrandMapHttp: 'json/V4/operate/brand-mapping/add-new-mapping.json',
  //新增品牌映射
  brandMapChildrenListHttp: 'json/V4/search/brand-mapping/list-mapped-brand.json',
  //获取品牌映射子列表
  brandMapChildrenChangeStatusHttp: 'json/V4/operate/brand-mapping/update-mapping-status.json',
  //品牌映射子集启用开关
  brandMapChildrenDeleteHttp: 'json/V4/operate/brand-mapping/delete-mapping.json' //删除品牌映射
};