import globalI18n from '@/locale';
const i18n = {
  'en-US': {
    ...globalI18n['en-US'],
    'login.form.title': 'Login to Arco Design Pro',
    'login.form.userName.errMsg': 'Username cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.userName.placeholder': 'Username: admin',
    'login.form.password.placeholder': 'Password: admin',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'Log in',
    'login.form.register': 'register account',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1': 'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2': 'Internationalization, routing configuration, state management everything',
    'login.banner.slogan3': 'Access visualization enhancement tool AUX',
    'login.banner.subSlogan3': 'Realize flexible block development',
    'dict.website.phishing': 'Phishing',
    'dict.rogue.mobile.app': 'Fake App',
    'dict.social.media.impersonation': 'Social Media Impersonation',
    'dict.vip.impersonation': 'VIP Impersonation Scam',
    'dict.content.fraud': 'Content Fraud',
    'dict.phone.scam': 'Phone Scam',
    'dict.malicious.search.engine.ranking': 'Malicious search engine ranking',
    'dict.anti.piracy': 'Anti-piracy',
    'dict.music': 'Music',
    'dict.film.television': 'Film and Television',
    'dict.novels': 'Novels',
    'dict.games': 'E-Games',
    'dict.sports.events': 'Sports Events',
    'dict.brand.taint': 'Brand Taint',
    'dict.web.bt': 'Web BT',
    'dict.app.bt': 'APP BT',
    'dict.domain.bt': 'Domain BT',
    'dict.ip.bt': 'IP BT',
    'dict.dark.web.threats': 'Dark Web Threats',
    'dict.data.breach': 'Data Breach',
    'dict.code.leakage': 'Code Leakage',
    'dict.attack.solicitation': 'Attack Solicitation',
    'dict.hacker.attack': 'Hacker Attack',
    'dict.misreporting': 'Misreporting',
    'dict.website.misreporting': 'Website misreporting',
    'dict.app.misreporting': 'App misreporting',
    'dict.ip.misreporting': 'IP misreporting',
    'dict.domain.misreporting': 'Domain name misreporting',
    'dict.domain.risks': 'Domain Risks',
    'dict.similar.domain': 'Similar Domain',
    'dict.expired.domain': 'Overdue Domain Abuse',
    'dict.expiration.domain': 'Expired Domain',
    'dict.udrp.domain': 'Domain Dispute',
    'dict.data breach': 'Data breach',
    'dict.document.leakage': 'Document Leakage',
    'dict.business.data.leakage': 'Business Data Leakage',
    'dict.source.code.leakage': 'Code Leakage',
    'dict.gdn.disruption.network': 'GDN is a global digital risk disruption network,',
    'dict.constructed.by.tianji': 'constructed by Tianji Partners based on years of digital risk protection practice, relying on global intelligence analysis and takedown capabilities, and working with digital infrastructure management platforms of and industry Collaborators.',
    'dict.gdn.dedicated': 'GDN is dedicated to unite ecological forces to resist digital risks and combat cybercrime.',
    'dict.clear.malicious.content.quickly': 'Clear quickly',
    'dict.improve.risk.protection.experience': 'Enhance protection',
    'dict.collaborative.governance.industries': 'Industiral collaboration',
    'dict.email.amount': 'Email/Account',
    'dict.password': 'Password',
    'dict.forgot.password': 'Forgot password',
    'dict.free.register': 'Free register',
    'dict.read.accept': 'Read and accept',
    'dict.user.service.agreement': 'User Service Agreement',
    'dict.check.user.service.agreement': 'Please read and accept 《User Service Agreement》'
  },
  'zh-CN': {
    ...globalI18n['zh-CN'],
    'login.form.title': '登录 Arco Design Pro',
    'login.form.userName.errMsg': '用户名不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.userName.placeholder': '用户名：admin',
    'login.form.password.placeholder': '密码：admin',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan1': '开箱即用的高质量模板',
    'login.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
    'login.banner.slogan2': '内置了常见问题的解决方案',
    'login.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
    'login.banner.slogan3': '接入可视化增强工具AUX',
    'login.banner.subSlogan3': '实现灵活的区块式开发',
    'dict.website.phishing': '网站钓鱼欺诈',
    'dict.rogue.mobile.app': '山寨仿冒APP',
    'dict.social.media.impersonation': '社交媒体仿冒',
    'dict.vip.impersonation': 'VIP仿冒欺诈',
    'dict.content.fraud': '内容侵权欺诈',
    'dict.phone.scam': '仿冒电话欺诈',
    'dict.malicious.search.engine.ranking': '搜索引擎恶意排名',
    'dict.anti.piracy': '知识产权保护',
    'dict.music': '音乐',
    'dict.film.television': '影视',
    'dict.novels': '小说',
    'dict.games': '游戏',
    'dict.sports.events': '体育赛事',
    'dict.brand.taint': '品牌污染',
    'dict.web.bt': '网站污染',
    'dict.app.bt': 'APP污染',
    'dict.domain.bt': '域名污染',
    'dict.ip.bt': 'IP污染',
    'dict.dark.web.threats': '暗网威胁',
    'dict.data.breach': '数据泄露',
    'dict.code.leakage': '代码泄露',
    'dict.attack.solicitation': '攻击召集',
    'dict.hacker.attack': '黑客攻击',
    'dict.misreporting': '威胁误报',
    'dict.website.misreporting': '网站威胁误报',
    'dict.app.misreporting': 'APP威胁误报',
    'dict.ip.misreporting': 'IP威胁误报',
    'dict.domain.misreporting': '域名威胁误报',
    'dict.domain.risks': '域名风险',
    'dict.similar.domain': '相似域名',
    'dict.expired.domain': '历史域名滥用',
    'dict.expiration.domain': '域名过期',
    'dict.udrp.domain': '域名申诉UDRP',
    'dict.data breach': '数据泄露',
    'dict.document.leakage': '文档泄露',
    'dict.business.data.leakage': '业务数据泄露',
    'dict.source.code.leakage': '代码泄露',
    'dict.threat.intelligence': '威胁情报',
    'dict.malicious.software.threats': '恶意软件威胁',
    'dict.malicious.attack.threats': '恶意攻击威胁',
    'dict.hacker.threats': '黑客团伙威胁',
    'dict.malicious.site.threats': '恶意站点威胁',
    'dict.gdn.disruption.network': 'GDN（Global Disruption Network）全球数字风险阻断网络，',
    'dict.constructed.by.tianji': '是天际友盟基于多年数字风险防护实践，依托全球情报分析和处置能力，携手业界数字基础设施管理平台和行业能力合作伙伴，共同打造的全球数字风险阻断网络。',
    'dict.gdn.dedicated': 'GDN宗旨：联合生态力量，抵御数字风险，打击网络犯罪。',
    'dict.clear.malicious.content.quickly': '快速阻止恶意内容',
    'dict.improve.risk.protection.experience': '提升风险防护体验',
    'dict.collaborative.governance.industries': '提升风险防护体验',
    'dict.email.amount': '邮箱/账号',
    'dict.password': '密码',
    'dict.forgot.password': '忘记密码',
    'dict.free.register': '免费注册',
    'dict.read.accept': '阅读并接受',
    'dict.user.service.agreement': '用户服务协议',
    'dict.check.user.service.agreement': '请先阅读并接受《用户服务协议》'
  }
};
export default i18n;